module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.13.7/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@1.13.3_gatsby@5.13.7_re_t5mdmpbnxa7hw7o5mg6ucflxpe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_gatsby@5.13.7_react-dom@18.0.0_react@18.0.0/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"2023.stateofhtml.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.0.0_react@18.0.0_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
